import { FaRegTrashCan } from "react-icons/fa6"

function UsersDrivers({ data }) {
    const deleteUser = async (userId) => {
        try {
            const response = await fetch(`https://amanat-24.ru/api-delete/${userId}`, {
                method: 'DELETE'
            });
            if (!response.ok) {
                throw new Error('Ошибка удаления пользователей');
            }
            const data = await response.json();
        } catch (error) {
            console.error('Ошибка:', error.message);
        }
    }

    return (
        <>
            { data && (
                <div className="flex flex-row space-x-6 px-12">
                    <div className="flex flex-col space-y-2">
                        <p className="text-xl text-black/50 font-semibold">№</p>
                        {data.map((item, index) => (
                            <p key={index} className="text-xl text-[#302777]">{index + 1}</p>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <p className="text-xl text-black/50 font-semibold">Имя</p>
                        {data.map((item, index) => (
                            <p className="text-xl text-[#302777]">{item.fullname}</p>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <p className="text-xl text-black/50 font-semibold">Фамилия</p>
                        {data.map((item, index) => (
                            <p key={index} className="text-xl text-[#302777]">{item.lastname}</p>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <p className="text-xl text-black/50 font-semibold">Отчество</p>
                        {data.map((item, index) => (
                            <p key={index} className="text-xl text-[#302777]">{item.middlename}</p>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <p className="text-xl text-black/50 font-semibold">Дата рождения</p>
                        {data.map((item, index) => (
                            <p key={index} className="text-xl text-[#302777]">{item.driverInfo ? item.driverInfo.birthday : '-'}</p>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <p className="text-xl text-black/50 font-semibold">Город</p>
                        {data.map((item, index) => (
                            <p key={index} className="text-xl text-[#302777]">{item.driverInfo ? item.driverInfo.location : '-'}</p>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <p className="text-xl text-black/50 font-semibold">Телефон</p>
                        {data.map((item, index) => (
                            <p key={index} className="text-xl text-[#302777]">{item.phone}</p>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <p className="text-xl text-black/50 font-semibold">Рейтинг</p>
                        {data.map((item, index) => (
                            <p key={index} className="text-xl text-[#302777]">{item.driverInfo ? (item.driverInfo.rate === null ? '0' : item.driverInfo.rate / item.driverInfo.countOfOrders) : '0'}</p>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <p className="text-xl text-black/50 font-semibold">Кол-во поездок</p>
                        {data.map((item, index) => (
                            <p key={index} className="text-xl text-[#302777]">{item.driverInfo ? ( item.driverInfo.countOfOrders !== null ? item.driverInfo.countOfOrders : '0' ): '0'}</p>
                        ))}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <p className="text-xl text-black/50 font-semibold">Тип транспорта</p>
                        {data.map((item, index) => (
                            <div className="flex flex-row items-center justify-between" key={index}>
                                <p className="text-xl text-[#302777]">{item.driverInfo ? item.driverInfo.typeOfVehicle : '-'}</p>
                                <button className="ml-12" onClick={() => deleteUser(item.userId)}>
                                    <FaRegTrashCan className="text-2xl text-red-500 hover:opacity-25"/>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            ) }
        </>
    )
};

export default UsersDrivers;
