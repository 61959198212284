import { IoCloseOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import Navbar from "../ui/Navbar";
import { useLocation, useParams } from "react-router-dom";

function ProfilePage() {
    const [data, setData] = useState([]);
    const { profileId } = useParams();
    const [category, setCategory] = useState('data');
    

    useEffect(() => {
        console.log(profileId);
        const fetchData = async () => {
            try {
                const response = await fetch(`https://amanat-24.ru/api-getUserById/${profileId}`);
                const responseData = await response.json();
                console.log(responseData);
                setData(responseData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        
    }, []);

    return (
        <div>
            <div className="mb-12 border-2 border-white">
                <Navbar/>
            </div>
            <div className="w-full h-[90vh] flex flex-col items-center justify-center space-y-4">
                <p className="text-2xl font-light text-[#302777]">Профиль - {data.fullname} {data.lastname}</p>
                <div className="flex flex-col space-y-6 items-center">
                    <img src={`https://amanat-24.ru/api-photoUsers/${data.photo}`} className="w-32 h-32 rounded-full"/>
                    <div className="flex flex-row space-x-12 border-b-[1px] border-b-black/25 pb-4">
                        <div className="flex flex-col space-y-2">
                            <p className="text-2xl font-medium text-[#302777]">Фамилия: <span className="font-light">{data.lastname}</span></p>
                            <p className="text-2xl font-medium text-[#302777]">Имя: <span className="font-light">{data.fullname}</span></p>
                            <p className="text-2xl font-medium text-[#302777]">Отчество: <span className="font-light">{data.middlename}</span></p>
                        </div>
                        <div className="flex flex-col space-y-2">
                            <p className="text-2xl font-medium text-[#302777]">Номер телефона: <span className="font-light">{data.phone}</span></p>
                            <p className="text-2xl font-medium text-[#302777]">Город: <span className="font-light">{data.city}</span></p>
                            <p className="text-2xl font-medium text-[#302777]">Тип аккаунта: <span className="font-light">{data.typeOfAccount === 'Driver' ? 'Водитель' : 'Клиент'}</span></p>
                        </div>
                    </div>
                    { data.driverInfo && (
                        <>
                            <div className="flex flex-row items-center justify-center space-x-6 w-full">
                                <button onClick={() => setCategory('data')}>
                                    <p className={ category === 'data' ? "text-xl text-[#302777] font-medium" : "text-xl text-[#302777]/50 font-medium hover:text-[#302777]/100" }>Данные о водителе</p>
                                </button>
                                <button onClick={() => setCategory('documents')}>
                                    <p className={ category === 'documents' ? "text-xl text-[#302777] font-medium" : "text-xl text-[#302777]/50 font-medium hover:text-[#302777]/100" }>Документы</p>
                                </button>
                            </div>
                            { category === 'data' && (
                                <div>
                                    <div className="flex flex-row justify-center pb-4">
                                        <div className="flex flex-row space-x-6">
                                            <p className="text-2xl font-medium text-[#302777] text-center">День рождения: <br></br><span className="font-light">{data.driverInfo.birthday}</span></p>
                                            <p className="text-2xl font-medium text-[#302777] text-center">Рейтинг: <br></br><span className="font-light">{data.driverInfo.rate !== null ? (data.driverInfo.rate / data.driverInfo.countOfOrders) : '0'}</span></p>
                                            <p className="text-2xl font-medium text-[#302777] text-center">Количество поездок: <br></br><span className="font-light">{data.driverInfo.countOfOrders !== null ? data.driverInfo.countOfOrders : '0'}</span></p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center pb-4 space-y-6">
                                        <p className="text-xl">Данные о транспорте</p>
                                        <div className="flex flex-row space-x-6">
                                            <p className="text-2xl font-medium text-[#302777] text-center">Длина: <br></br><span className="font-light">{data.driverInfo.long} м</span></p>
                                            <p className="text-2xl font-medium text-[#302777] text-center">Ширина: <br></br><span className="font-light">{data.driverInfo.width} м</span></p>
                                            <p className="text-2xl font-medium text-[#302777] text-center">Высота: <br></br><span className="font-light">{data.driverInfo.height} м</span></p>
                                        </div>
                                        <div className="flex flex-row space-x-6">
                                            <p className="text-2xl font-medium text-[#302777] text-center">Минимальный вес: <br></br><span className="font-light">{data.driverInfo.minWeight} кг</span></p>
                                            <p className="text-2xl font-medium text-[#302777] text-center">Максимальный вес: <br></br><span className="font-light">{data.driverInfo.maxWeight}</span></p>
                                            <p className="text-2xl font-medium text-[#302777] text-center">Минимальный объем: <br></br><span className="font-light">{data.driverInfo.minVolume}</span></p>
                                        </div>
                                        <div className="flex flex-row space-x-6">
                                            <p className="text-2xl font-medium text-[#302777] text-center">Максимальный объем: <br></br><span className="font-light">{data.driverInfo.maxVolume}</span></p>
                                            <p className="text-2xl font-medium text-[#302777] text-center">Тип машины: <br></br><span className="font-light">{data.driverInfo.typeOfVehicle}</span></p>
                                        </div>
                                    </div>
                                </div>
                            ) }
                            { category === 'documents' && (
                                <div className="flex flex-row flex-wrap justify-center w-10/12">
                                    <div className="flex flex-col justify-center items-center m-6">
                                        <p className="text-2xl text-[#302777] mb-2">Селфи</p>
                                        <img src={`https://amanat-24.ru/api-selfies/${data.driverInfo.selfie}`} className="w-72 h-40 rounded-xl"/>
                                    </div>
                                    <div className="flex flex-col justify-center items-center m-6">
                                        <p className="text-2xl text-[#302777] mb-2">Тех.паспорт</p>
                                        <img src={`https://amanat-24.ru/api-techPassport/${data.driverInfo.techPassport}`} className="w-72 h-40 rounded-xl"/>
                                    </div>
                                    <div className="flex flex-col justify-center items-center m-6">
                                        <p className="text-2xl text-[#302777] mb-2">Водительские права спереди</p>
                                        <img src={`https://amanat-24.ru/api-driverRights/front/${data.driverInfo.photoRightFront}`} className="w-72 h-40 rounded-xl"/>
                                    </div>
                                    <div className="flex flex-col justify-center items-center m-6">
                                        <p className="text-2xl text-[#302777] mb-2">Водительские права сзади</p>
                                        <img src={`https://amanat-24.ru/api-driverRights/back/${data.driverInfo.photoRightBack}`} className="w-72 h-40 rounded-xl"/>
                                    </div>
                                    <div className="flex flex-col justify-center items-center m-6">
                                        <p className="text-2xl text-[#302777] mb-2">Удостоверение личности</p>
                                        <img src={`https://amanat-24.ru/api-idCard/${data.driverInfo.idCard}`} className="w-72 h-40 rounded-xl"/>
                                    </div>
                                </div>
                            ) }
                        </>
                    ) }
                </div>
            </div>
        </div>
    )
};

export default ProfilePage;