import { IoCloseOutline } from "react-icons/io5";
import { useState } from "react";

function AddCarTariff({ onClose }) {
    const [category, onChangeCategory] = useState(null);
    const [minTime, onChangeMinTime] = useState(null);
    const [tariff, onChangeTariff] = useState(null);
    const [vat, onChangeVat] = useState(null);
    const [incomeForCompany, onChangeIncomeForCompany] = useState(null);

    const add = async () => {
        try {
            const response = await fetch('https://amanat-24.ru/api-addCarTariff', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    category,
                    minTime,
                    tariff,
                    vat,
                    incomeForCompany
                })
            });

            const data = await response.json();

            if (data.success) {
                // Если тариф успешно добавлен, закрываем модальное окно
                onClose();
            } else {
                console.error('Error adding car tariff:', data.message);
            }
        } catch (error) {
            console.error('Error adding car tariff:', error);
        }
    };

    return (
        <div className="fixed top-0 left-0 z-50 w-screen h-screen bg-black/15 flex justify-center items-center">
            <div className="bg-white p-6 rounded-2xl px-12 w-3/12">
                <div className="w-full flex flex-row items-end justify-end">
                    <button onClick={onClose} className="hover:opacity-50">
                        <IoCloseOutline className="text-4xl text-[#302777]"/>
                    </button>
                </div>
                <div className="w-full border-2 border-[#302777] p-3 rounded-xl mt-6">
                    <p className="bg-white p-1 text-[#302777] absolute z-1 -mt-8 ml-0">Категория авто</p>
                    <input value={category} onChange={e => onChangeCategory(e.target.value)} placeholder="Введите значение" className="bg-transparent outline-none text-xl"/>
                </div>
                <div className="w-full border-2 border-[#302777] p-3 rounded-xl mt-6">
                    <p className="bg-white p-1 text-[#302777] absolute z-1 -mt-8 ml-0">Мин. расчёт</p>
                    <input value={minTime} onChange={e => onChangeMinTime(e.target.value)} placeholder="Введите значение" className="bg-transparent outline-none text-xl"/>
                </div>
                <div className="w-full border-2 border-[#302777] p-3 rounded-xl mt-6">
                    <p className="bg-white p-1 text-[#302777] absolute z-1 -mt-8 ml-0">Тариф в тенге</p>
                    <input value={tariff} onChange={e => onChangeTariff(e.target.value)} placeholder="Введите значение" className="bg-transparent outline-none text-xl"/>
                </div>
                <div className="w-full border-2 border-[#302777] p-3 rounded-xl mt-6">
                    <p className="bg-white p-1 text-[#302777] absolute z-1 -mt-8 ml-0">НДС</p>
                    <input value={vat} onChange={e => onChangeVat(e.target.value)} placeholder="Введите значение" className="bg-transparent outline-none text-xl"/>
                </div> 
                <div className="w-full border-2 border-[#302777] p-3 rounded-xl mt-6">
                    <p className="bg-white p-1 text-[#302777] absolute z-1 -mt-8 ml-0">Доход для ТОО</p>
                    <input value={incomeForCompany} onChange={e => onChangeIncomeForCompany(e.target.value)} placeholder="Введите значение" className="bg-transparent outline-none text-xl"/>
                </div>
                <button onClick={add} className="w-full border-2 border-[#2C9356] p-2 mt-6 rounded-xl group hover:bg-[#2C9356]">
                    <p className="text-xl text-[#2C9356] group-hover:text-white">Добавить запись</p>
                </button>
            </div>
        </div>
    )
};

export default AddCarTariff;