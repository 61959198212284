import { useEffect, useState } from "react";
import ProfilePage from "../pages/ProfilePage";
import Users from "./typesOfUsers/Users";
import UsersDrivers from "./typesOfUsers/UsersDrivers";
import DriversOnline from "./typesOfUsers/DriversOnline";

function Driver() {
    const [data, setData] = useState([]);
    const [isOpenProfile, setOpenProfile] = useState(true);
    const [selectedId, setSelectedId] = useState();
    const [category, setCategory] = useState('getAllUsers');
    const [isLoading, setIsLoading] = useState(false);
    
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalClients, setTotalClients] = useState(0);
    const [totalDrivers, setTotalDrivers] = useState(0);
    const [totalDriversOnline, setTotalDriversOnline] = useState(0);
    const [totalDriversOffline, setTotalDriversOffline] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                console.log('categoty', category)
                const response = await fetch(`https://amanat-24.ru/api-${category}`);
                const responseData = await response.json();
                await setData(responseData);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchDataStatistic();
        fetchData();
    }, [category]);

    const fetchDataStatistic = async () => {
        try {
            setIsLoading(true)
            console.log('categoty', category)
            const response = await fetch(`https://amanat-24.ru/api-usersStatistics`);
            const responseData = await response.json();
            console.log(responseData);
            setTotalUsers(responseData.totalUsers);
            setTotalClients(responseData.totalClients);
            setTotalDrivers(responseData.totalDrivers);
            setTotalDriversOnline(responseData.totalDriversOnline);
            setTotalDriversOffline(responseData.totalDriversOffline);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <div className="px-4 flex flex-col">
            <div className="w-full justify-center items-center flex flex-row space-x-6 py-2">
                <div className="bg-white rounded-2xl w-48 h-48 border-[1px] flex flex-col justify-center items-center">
                    <p className="text-black/25 text-xl text-center font-medium">Кол-во пользователей</p>
                    <p className="mt-2 text-2xl text-center text-[#302777] font-medium">{totalUsers}</p>
                </div>
                <div className="bg-white rounded-2xl w-48 h-48 border-[1px] flex flex-col justify-center items-center">
                    <p className="text-black/25 text-xl text-center font-medium">Кол-во клиентов</p>
                    <p className="mt-2 text-2xl text-center text-[#302777] font-medium">{totalClients}</p>
                </div>
                <div className="bg-white rounded-2xl w-48 h-48 border-[1px] flex flex-col justify-center items-center">
                    <p className="text-black/25 text-xl text-center font-medium">Кол-во водителей</p>
                    <p className="mt-2 text-2xl text-center text-[#302777] font-medium">{totalDrivers}</p>
                </div>
                <div className="bg-white rounded-2xl w-48 h-48 border-[1px] flex flex-col justify-center items-center">
                    <p className="text-black/25 text-xl text-center font-medium">Кол-во водителей на линии</p>
                    <p className="mt-2 text-2xl text-center text-[#302777] font-medium">{totalDriversOnline}</p>
                </div>
                <div className="bg-white rounded-2xl w-48 h-48 border-[1px] flex flex-col justify-center items-center">
                    <p className="text-black/25 text-xl text-center font-medium">Кол-во водителей не на линии</p>
                    <p className="mt-2 text-2xl text-center text-[#302777] font-medium">{totalDriversOffline}</p>
                </div>
            </div>
            <div className="w-full justify-center items-center flex flex-row space-x-6 py-2 mb-2">
                <button onClick={() => setCategory('getAllUsers')}>
                    <p className={ category === 'getAllUsers' ? "text-xl text-[#302777] font-medium" : "text-xl text-[#302777]/25 font-medium hover:text-[#302777]" }>Все пользователи</p>
                </button>
                <button onClick={() => setCategory('getAllDrivers')}>
                    <p className={ category === 'getAllDrivers' ? "text-xl text-[#302777] font-medium" : "text-xl text-[#302777]/25 font-medium hover:text-[#302777]" }>Все водители</p>
                </button>
                <button onClick={() => setCategory('getAllDriversOnline')}>
                    <p className={ category === 'getAllDriversOnline' ? "text-xl text-[#302777] font-medium" : "text-xl text-[#302777]/25 font-medium hover:text-[#302777]" }>Водители на линии</p>
                </button>
            </div>
            { !isLoading && (
                <>
                    { category === 'getAllUsers' && <Users onOpen={() => setOpenProfile(true)} selectId={() => setSelectedId()} data={data}/> }
                    { category === 'getAllDrivers' && <UsersDrivers data={data}/> }
                    { category === 'getAllDriversOnline' && <DriversOnline data={data}/> }
                </>
            ) }
        </div>
    )
}

export default Driver;