import Navbar from "../ui/Navbar";
import Tariffs from "../ui/Tariffs";

function TariffsPage() {
    return (
        <div>
            <div className="mb-16 border-2 border-white">
                <Navbar/>
            </div>
            <Tariffs/>
        </div>
    )
};

export default TariffsPage;