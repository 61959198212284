import Navbar from "../ui/Navbar";
import Settings from "../ui/Settings";

function SettingsPage() {
    return (
        <div>
            <div className="mb-16 border-2 border-white">
                <Navbar/>
            </div>
            <Settings/>
        </div>
    )
};

export default SettingsPage;