import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function Navbar() {
    const [activePage, setActivePage] = useState('');
    const location = useLocation();

    useEffect(() => {
        // Функция для извлечения параметра после '/admin-panel/'
        const getPageFromUrl = () => {
            const parts = location.pathname.split('/');
            setActivePage(parts);
            setActivePage(parts[2]);
            console.log(activePage);
        };

        getPageFromUrl();
    }, [location]);

    return (
        <div className="flex flex-row space-x-4 w-full top-0 justify-between p-4 overflow-x-hidden overflow-y-hidden fixed bg-white">
            <div className="flex flex-row space-x-6">
                <Link to='/admin-panel/drivers'>
                    <p className={ activePage === 'drivers' ? "text-xl text-[#302777] hover:opacity-50" : "text-xl text-[#302777] hover:opacity-100 opacity-50" }>Водители</p>
                </Link>
                <Link to='/admin-panel/tariffs'>
                    <p className={ activePage === 'tariffs' ? "text-xl text-[#302777] hover:opacity-50" : "text-xl text-[#302777] hover:opacity-100 opacity-50" }>Тарифы</p>
                </Link>
                <Link to='/admin-panel/settings'>
                    <p className={ activePage === 'settings' ? "text-xl text-[#302777] hover:opacity-50" : "text-xl text-[#302777] hover:opacity-100 opacity-50" }>Настройки</p>
                </Link>
                <Link to='/admin-panel/chats'>
                    <p className={ activePage === 'chats' ? "text-xl text-[#302777] hover:opacity-50" : "text-xl text-[#302777] hover:opacity-100 opacity-50" }>Чаты</p>
                </Link>
            </div>
            <p className="text-xl text-[#302777] font-semibold">Admin</p>
        </div>
    );
};

export default Navbar;