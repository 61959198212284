import { IoClose } from "react-icons/io5";
import { useEffect, useState } from "react";
import { HiOutlinePlus } from "react-icons/hi";

function Settings() {
    const [cities, setCities] = useState([]);
    const [typesOfVehicle, setTypesOfVehicle] = useState([]);
    const [materials, setMaterials] = useState([]);

    const [isAddCity, setIsAddCity] = useState(false);
    const [newCity, onChangeNewCity] = useState();
    const [isCityError, setIsCityError] = useState(false);

    const [isAddType, setIsAddType] = useState(false);
    const [newType, onChangeNewType] = useState();
    const [isAddTypeError, setIsAddTypeError] = useState(false)

    const [isAddMaterial, setIsAddMaterial] = useState(false);
    const [newMaterial, onChangeNewMaterial] = useState();
    const [isAddMaterialError, setIsAddMaterialError] = useState(false)

    useEffect(() => {
        fetchCities();
        fetchTypesOfVehicle();
        fetchMaterials();
    }, [cities, typesOfVehicle, materials]);
    
    const fetchCities = async () => {
        try {
            const response = await fetch('https://amanat-24.ru/api-getCities');
            const responseData = await response.json();
            if (responseData.data && Array.isArray(responseData.data)) {
                setCities(responseData.data);
            } else {
                
            }
        } catch (error) {
            
        }
    };

    const fetchTypesOfVehicle = async () => {
        try {
            const response = await fetch('https://amanat-24.ru/api-getTypesOfVehicle');
            const responseData = await response.json();
            if (responseData.data && Array.isArray(responseData.data)) {
                setTypesOfVehicle(responseData.data);
            } else {
                
            }
        } catch (error) {
            
        }
    };

    const fetchMaterials = async () => {
        try {
            const response = await fetch('https://amanat-24.ru/api-getMaterials');
            const responseData = await response.json();
            if (responseData.data && Array.isArray(responseData.data)) {
                setMaterials(responseData.data);
            } else {
        
            }
        } catch (error) {

        }
    };

    const addCity = async () => {
        if (!newCity) {
            setIsCityError(true);
        } else {
            setIsCityError(false);
    
            try {
                const response = await fetch('https://amanat-24.ru/api-addCity', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ newCity })
                });
    
                const data = await response.json();
    
                if (data.success) {
                    // Если запрос успешен, устанавливаем setIsAddCity в false
                    setIsAddCity(false);
                    onChangeNewCity();
                } else {
                    // Обработка ошибки, если запрос не успешен
                    
                }
            } catch (error) {
                
            }
        }
    };

    const addType = async () => {
        if (!newType) {
            setIsAddTypeError(true);
        } else {
            setIsAddTypeError(false);
    
            try {
                const response = await fetch('https://amanat-24.ru/api-addType', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ newType })
                });
    
                const data = await response.json();
    
                if (data.success) {
                    // Если запрос успешен, устанавливаем setIsAddCity в false
                    setIsAddType(false);
                    onChangeNewType();
                } else {
                    // Обработка ошибки, если запрос не успешен
                    
                }
            } catch (error) {
                
            }
        }
    };

    const addMaterial = async () => {
        if (!newMaterial) {
            setIsAddMaterialError(true);
        } else {
            setIsAddMaterialError(false);
    
            try {
                const response = await fetch('https://amanat-24.ru/api-addMaterial', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ newMaterial })
                });
    
                const data = await response.json();
    
                if (data.success) {
                    // Если запрос успешен, устанавливаем setIsAddCity в false
                    setIsAddMaterial(false);
                    onChangeNewMaterial();
                } else {
                    // Обработка ошибки, если запрос не успешен
                    
                }
            } catch (error) {
            
            }
        }
    };

    const deleteOption = async (url, id) => {
        
        try {
            const response = await fetch(`https://amanat-24.ru/api-${url}/${id}`, {
                method: 'DELETE'
            });
            if (!response.ok) {
                throw new Error('Ошибка удаления пользователей');
            }
    
            const data = await response.json();
            
        } catch (error) {
            
        }
    }

    return (
        <div className="w-screen px-12">
            <div className="flex flex-rows">
                <div className="space-x-4 flex flex-row">
                    <p className="text-xl text-[#302777] font-semibold">Города:</p>
                    <div className="grid grid-cols-7 gap-4 grid-flow-row items-center">
                        {cities.map((city, index) => (
                            <div key={index} className="border-[#2C9356] border-2 p-1 px-6 justify-center rounded-full flex flex-row space-x-2 items-center min-w-min">
                                <p className="text-lg text-nowrap text-[#2C9356]">{city.name}</p>
                                <button onClick={() => deleteOption('deleteCity', city.id)} className="hover:opacity-50">
                                    <IoClose className="text-2xl text-[#2C9356]"/>
                                </button>
                            </div>
                        ))}
                        { !isAddCity ? (
                            <button onClick={() => (setIsAddCity(true), setIsAddType(false), setIsAddMaterial(false))} className="w-10 h-10 rounded-full bg-[#302777] flex justify-center items-center hover:opacity-50">
                                <HiOutlinePlus className=" text-white text-2xl"/>
                            </button>
                        ) : (
                            <div className="flex flex-row space-x-2 w-96">
                                <div className={ isCityError ? "border-2 p-2 border-red-500 rounded-full px-6" : "border-2 p-2 border-[#302777] rounded-full px-6"}>
                                    <input value={newCity} onChange={e => onChangeNewCity(e.target.value)} className="text-[#302777] bg-transparent outline-none" placeholder="Введите название"/>
                                </div>
                                <button onClick={addCity} className="w-10 h-10 rounded-full bg-[#302777] flex justify-center items-center hover:opacity-50">
                                    <HiOutlinePlus className=" text-white text-2xl"/>
                                </button>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
            <div className="flex flex-rows mt-10">
                <div className="space-x-4 flex flex-row">
                    <p className="text-xl text-[#302777] font-semibold text-nowrap">Типы транспорта:</p>
                    <div className="grid grid-cols-5 gap-4 grid-flow-row">
                        {typesOfVehicle.map((type, index) => (
                            <div key={index} className="border-[#2C9356] border-2 p-1 px-6 justify-center rounded-full flex flex-row space-x-2 items-center min-w-min">
                                <p className="text-lg text-nowrap text-[#2C9356]">{type.name}</p>
                                <button onClick={() => deleteOption('deleteType', type.id)} className="hover:opacity-50">
                                    <IoClose className="text-2xl text-[#2C9356]"/>
                                </button>
                            </div>
                        ))}
                        { !isAddType ? (
                            <button onClick={() => (setIsAddType(true), setIsAddCity(false), setIsAddMaterial(false))} className="w-10 h-10 rounded-full bg-[#302777] flex justify-center items-center hover:opacity-50">
                                <HiOutlinePlus className=" text-white text-2xl"/>
                            </button>
                        ) : (
                            <div className="flex flex-row space-x-2 w-96">
                                <div className={ isAddTypeError ? "border-2 p-2 border-red-500 rounded-full px-6" : "border-2 p-2 border-[#302777] rounded-full px-6"}>
                                    <input value={newType} onChange={e => onChangeNewType(e.target.value)} className="text-[#302777] bg-transparent outline-none" placeholder="Введите название"/>
                                </div>
                                <button onClick={addType} className="w-10 h-10 rounded-full bg-[#302777] flex justify-center items-center hover:opacity-50">
                                    <HiOutlinePlus className=" text-white text-2xl"/>
                                </button>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
            <div className="flex flex-rows mt-10">
                <div className="space-x-4 flex flex-row">
                    <p className="text-xl text-[#302777] font-semibold text-nowrap">Материалы:</p>
                    <div className="grid grid-cols-8 gap-4 grid-flow-row">
                        {materials.map((material, index) => (
                            <div key={index} className="border-[#2C9356] border-2 p-1 px-6 justify-center rounded-full flex flex-row space-x-2 items-center min-w-min">
                                <p className="text-lg text-nowrap text-[#2C9356]">{material.name}</p>
                                <button onClick={() => deleteOption('deleteMaterial', material.id)} className="hover:opacity-50">
                                    <IoClose className="text-2xl text-[#2C9356]"/>
                                </button>
                            </div>
                        ))}
                        { !isAddMaterial ? (
                            <button onClick={() => (setIsAddType(false), setIsAddCity(false), setIsAddMaterial(true))} className="w-10 h-10 rounded-full bg-[#302777] flex justify-center items-center hover:opacity-50">
                                <HiOutlinePlus className=" text-white text-2xl"/>
                            </button>
                        ) : (
                            <div className="flex flex-row space-x-2 w-96">
                                <div className={ isAddMaterialError ? "border-2 p-2 border-red-500 rounded-full px-6" : "border-2 p-2 border-[#302777] rounded-full px-6"}>
                                    <input value={newMaterial} onChange={e => onChangeNewMaterial(e.target.value)} className="text-[#302777] bg-transparent outline-none" placeholder="Введите название"/>
                                </div>
                                <button onClick={addMaterial} className="w-10 h-10 rounded-full bg-[#302777] flex justify-center items-center hover:opacity-50">
                                    <HiOutlinePlus className=" text-white text-2xl"/>
                                </button>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Settings;

