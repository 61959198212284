import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FaRegTrashCan } from "react-icons/fa6";

function Chats() {
    const [chats, setChats] = useState([]);

    useEffect(() => {
        // Функция для выполнения запроса к бэкенду
        const fetchChats = async () => {
            try {
                const response = await fetch('https://amanat-24.ru/api-getAllChats');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setChats(data); // Сохраняем полученные данные в состояние
            } catch (error) {
                console.error('There was a problem fetching chats:', error);
            }
        };

        // Вызываем функцию fetchChats при загрузке компонента
        fetchChats();
    }, []);

    const deleteDialog = async (clientId) => {
        console.log(clientId);
        console.log('delete dialog');
        try {
            const response = await fetch('https://amanat-24.ru/api-deleteChat', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ clientId: clientId })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

        } catch (error) {
            console.error('There was a problem fetching dialogs:', error);
        }
    }

    return (
        <div className="w-full p-4 flex flex-col items-center justify-center">
            <div className="flex flex-row items-center space-x-6">
                <p className="text-3xl text-[#302777] font-light">Сообщения:</p>
            </div>
            { chats.length !== 0 ? (
                <>
                    {chats.map((chat, index) => (
                        <div key={index} className="rounded-xl flex flex-row items-center p-4">
                            <div className="flex flex-row space-x-4 items-center bg-white">
                                <Link to={`/admin-panel/chats/dialog/${chat.clientId}`} state={{ clientName: chat.clientName, clientPhoto: chat.clientPhoto }} className='flex flex-row space-x-4 items-center hover:opacity-50'>
                                    <img src={`https://amanat-24.ru/api-photoUsers/${chat.clientPhoto}`} className="h-12 w-12 rounded-full"/>
                                    <div className="">
                                        <p className="text-xl font-semibold text-[#302777]">{chat.clientName}</p>
                                        <p className="text-xl font-semibold text-[#302777] opacity-50 w-72">{chat.lastMessage}</p>
                                    </div>
                                </Link>
                                <button className='hover:opacity-50' onClick={() => deleteDialog(chat.clientId)}>
                                    <FaRegTrashCan className='text-red-500 text-2xl'/>
                                </button>
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <p className='text-xl text-[#302777] font-bold mt-10'>Пока сообщений нет</p>
            ) }
        </div>
    );
};

export default Chats;
// key={index} to={`/admin-panel/chats/dialog/${chat.clientId}`} state={{ clientName: chat.clientName, clientPhoto: chat.clientPhoto }}
