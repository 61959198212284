import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AuthPage from './components/pages/AuthPage';
import ProfilePage from './components/pages/ProfilePage';
import DriversPage from './components/pages/DriversPage';
import SettingsPage from './components/pages/SettingsPage';
import TariffsPage from './components/pages/TariffsPage';
import ChatsPage from './components/pages/ChatsPage';  // Добавлено
import DialogPage from './components/pages/DialogPage';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Проверка наличия токена в cookie
    const authToken = getCookie('auth_token');
    setIsLoggedIn(!!authToken);
  }, [isLoggedIn]);

  // Функция для получения значения cookie по имени
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  return (
    <Router>
      <Routes>
        <Route path='/admin-panel/drivers' element={<DriversPage/>}/>
        <Route path='/admin-panel/settings' element={<SettingsPage/>}/>
        <Route path='/admin-panel/tariffs' element={<TariffsPage/>}/>
        <Route path='/admin-panel/chats' element={<ChatsPage/>}/>  {/* Добавлено */}
        <Route path='/admin-panel/chats/dialog/:dialogId' element={<DialogPage/>}/>
        <Route path='/admin-panel/profile/:profileId' element={<ProfilePage/>}/>
      </Routes>
    </Router>
  );
}

export default App;


{/* <Route path='/admin-panel' element={ isLoggedIn ? <MainPage/> : <AuthPage/>}/>
<Route path='/profile/:id' element={<ProfilePage/>}/> */}
