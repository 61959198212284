import { FaRegTrashCan } from "react-icons/fa6"
import { Link } from "react-router-dom";

function Users({ data, onOpen, selectId }) {

    const deleteUser = async (userId) => {
        try {
            const response = await fetch(`https://amanat-24.ru/api-delete/${userId}`, {
                method: 'DELETE'
            });
            if (!response.ok) {
                throw new Error('Ошибка удаления пользователей');
            }
    
            const data = await response.json();
            
        } catch (error) {
            console.error('Ошибка:', error.message);
        }
    }
    
    return (
        <div className="flex flex-row space-x-6 px-12">
            <div className="flex flex-col space-y-2">
                <p className="text-xl text-black/50 font-semibold">№</p>
                {data.map((item, index) => (
                    <p key={index} className="text-xl text-[#302777]">{index + 1}</p>
                ))}
            </div>
            <div className="flex flex-col space-y-2">
                <p className="text-xl text-black/50 font-semibold">Имя</p>
                {data.map((item, index) => (
                    <p key={item.userId} className="text-xl text-[#302777]">{item.fullname}</p>
                ))}
            </div>
            <div className="flex flex-col space-y-2">
                <p className="text-xl text-black/50 font-semibold">Фамилия</p>
                {data.map((item, index) => (
                    <p key={item.userId} className="text-xl text-[#302777]">{item.lastname}</p>
                ))}
            </div>
            <div className="flex flex-col space-y-2">
                <p className="text-xl text-black/50 font-semibold">Отчество</p>
                {data.map((item, index) => (
                    <p key={item.userId} className="text-xl text-[#302777]">{item.middlename}</p>
                ))}
            </div>
            <div className="flex flex-col space-y-2">
                <p className="text-xl text-black/50 font-semibold">Город</p>
                {data.map((item, index) => (
                    <p key={item.userId} className="text-xl text-[#302777]">{item.city}</p>
                ))}
            </div>
            <div className="flex flex-col space-y-2">
                <p className="text-xl text-black/50 font-semibold">Телефон</p>
                {data.map((item, index) => (
                    <p key={item.userId} className="text-xl text-[#302777]">{item.phone}</p>
                ))}
            </div>
            <div className="flex flex-col space-y-2">
                <p className="text-xl text-black/50 font-semibold">Тип аккаунта</p>
                {data.map((item, index) => (
                    <div className="flex flex-row items-center justify-between">
                        <p key={item.userId} className="text-xl text-[#302777]">{item.typeOfAccount === 'Driver' ? 'Водитель' : 'Клиент'}</p>
                        <Link to={`/admin-panel/profile/${item.id}`} className="ml-12 hover:opacity-50">
                            <p className="text-xl text-[#302777]">Посмотреть профиль</p>
                        </Link>
                        <button className="ml-12" onClick={() => deleteUser(item.userId)}>
                            <FaRegTrashCan className="text-2xl text-red-500 hover:opacity-25"/>
                        </button>
                    </div>
                ))} 
            </div>
        </div>
    )
};

export default Users