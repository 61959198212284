import Navbar from "../ui/Navbar";
import Chats from "../ui/Chats";
import { IoSend, IoCheckmark, IoCheckmarkDoneOutline  } from "react-icons/io5";
import { FiPaperclip } from "react-icons/fi";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ViewImage from "../ui/modal/ViewImage";

function DialogPage() {
    const location = useLocation();
    const { clientName, clientPhoto } = location.state;
    const { dialogId } = useParams();
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [isShowImageModal, setIsShowImageModal] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');

    const Message = ({ text, time }) => {
        return (
            <div className="flex justify-end mb-2">
                <div className="bg-[#302777] rounded-2xl p-3 max-w-[500px] flex flex-row">
                    <p className="text-white text-lg">{text}</p>
                    <div className="flex flex-row items-end justify-end ml-2">
                        <div className="flex flex-row items-center space-x-1">
                            <p className="text-white/60 text-md">{time}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    const MessageClient = ({ message }) => {
        console.log(message);
        return (
            <div>
                <div className="flex flex-row items-center flex-wrap w-64">
                    {Array.from({ length: 5 }).map((_, index) => {
                        const photoKey = `photoMessage${index + 1}`;
                        const photoUrl = message[photoKey];
                        if (photoUrl) {
                            return (
                                <button onClick={() => (setIsShowImageModal(true), setSelectedImageUrl(`https://amanat-24.ru/api-chatPhoto/${photoUrl}`))} key={index} className="w-16 h-16 rounded-xl m-2 hover:opacity-50">
                                    <img src={`https://amanat-24.ru/api-chatPhoto/${photoUrl}`} alt={`Photo ${index + 1}`} className="w-full h-full object-cover rounded-xl" />
                                </button>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
                <div className="flex justify-start mb-2">
                    <div className="bg-[#302777] rounded-2xl p-3 max-w-[500px] flex flex-row">
                        <p className="text-white text-lg">{message.textMessage}</p>
                        <div className="flex flex-row items-end justify-end ml-2">
                            <div className="flex flex-row items-center space-x-1">
                                <p className="text-white/60 text-md">{message.time}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        const interval = setInterval(fetchDialogs, 1000);

        return () => {
            clearInterval(interval); 
        }
    }, [dialogId]);

    const fetchDialogs = async () => {
        try {
            const response = await fetch('https://amanat-24.ru/api-getDialogsByClientId', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ clientId: dialogId })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setMessages(data);
        } catch (error) {
            console.error('There was a problem fetching dialogs:', error);
        }
    };

    const sendMessage = async () => {
        try {
            const response = await fetch('https://amanat-24.ru/api-sendMessageAdmin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    clientId: dialogId,
                    textMessage: message,
                    typeMessage: 'admin',
                    time: getTimeString()
                })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            } else {
                setMessage('');
            }
            
        } catch (error) {
            console.error('There was a problem sending the message:', error);
        }
    }

    const getTimeString = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    return (
        <div className="w-full h-screen flex flex-col">
            <div className="mb-12 border-2 border-white">
                <Navbar/>
            </div>
            <div className="w-full p-6 flex flex-row space-x-6 items-center">
                <img src={`https://amanat-24.ru/api-photoUsers/${clientPhoto}`}  className="h-12 w-12 rounded-full"/> 
                <p className="text-2xl text-[#302777]">{clientName}</p>
            </div>
            <div className="w-full h-[70vh] flex-grow px-6 flex flex-col space-y-4 overflow-y-auto">
                {messages.map((message, index) => (
                    message.typeMessage === 'client' ? (
                        <MessageClient message={message} key={index}/>
                    ) : (
                        <Message isReaded={message.isReaded} key={index} text={message.textMessage} time={message.time}/>
                    )
                ))}
            </div>
            <div className="w-full flex-grow p-6 flex flex-row space-x-6 items-center">
                <input value={message} onChange={e => setMessage(e.target.value)} aria-multiline className="text-2xl text-black/50 outline-none bg-transparent w-full" placeholder="Введите сообщение"/>
                { message !== '' && (
                    <button onClick={sendMessage}  className="hover:opacity-50">
                        <IoSend className="text-3xl text-[#302777]"/>
                    </button>
                ) }
            </div>
            { isShowImageModal && <ViewImage selectedImage={selectedImageUrl} onClose={() => (setIsShowImageModal(false), setSelectedImageUrl(''))}/> }
        </div>
    );
};

export default DialogPage;
