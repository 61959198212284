import Navbar from "../ui/Navbar";
import Chats from "../ui/Chats";

function ChatsPage() {
    return (
        <div className="w-full h-screen">
            <div className="mb-12 border-2 border-white">
                <Navbar/>
            </div>
            <Chats/>
        </div>
    );
};

export default ChatsPage;