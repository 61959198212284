import React, { useState, useEffect } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import CarTariffTable from "./tables/CarTariffTable";
import CargoTariffTable from "./tables/CargoTariffTable";
import DeliveryTariffTable from "./tables/DeliveryTariffTable";
import DeliveryTariffCapitalTable from "./tables/DeliveryTariffCapitalTable";

function Tariffs() {
    const [tab, setTab] = useState('CarTariff');
    return (
        <div className="flex flex-col">
            <div className="w-screen flex-row flex justify-center items-center space-x-6">
                <button onClick={() => setTab('CarTariff')}>
                    <p className={ tab === 'CarTariff' ? "text-xl text-[#302777]" : "text-xl text-[#302777] opacity-50 hover:opacity-100"}>Тарифы по городу</p>
                </button>
                <button onClick={() => setTab('CargoTariff')}>
                    <p className={ tab === 'CargoTariff' ? "text-xl text-[#302777]" : "text-xl text-[#302777] opacity-50 hover:opacity-100"}>Тарифы по РК</p>
                </button>
                <button onClick={() => setTab('DeliveryTariff')}>
                    <p className={ tab === 'DeliveryTariff' ? "text-xl text-[#302777]" : "text-xl text-[#302777] opacity-50 hover:opacity-100"}>Тарифы на отдельное авто</p>
                </button>
                <button onClick={() => setTab('DeliveryTariffCapitalTable')}>
                    <p className={ tab === 'DeliveryTariffCapitalTable' ? "text-xl text-[#302777]" : "text-xl text-[#302777] opacity-50 hover:opacity-100"}>Тарифы столица</p>
                </button>
            </div>
            { tab === 'CarTariff' && <CarTariffTable/> }
            { tab === 'CargoTariff' && <CargoTariffTable/> }
            { tab === 'DeliveryTariff' && <DeliveryTariffTable/> }
            { tab === 'DeliveryTariffCapitalTable' && <DeliveryTariffCapitalTable/> }
        </div>
    )
}

export default Tariffs;
