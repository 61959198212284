import Driver from "../ui/Drivers";
import Navbar from "../ui/Navbar";

function DriversPage() {
    return (
        <div>
            <div className="mb-12 border-2 border-white">
                <Navbar/>
            </div>
            <Driver/>
        </div>
    )
};

export default DriversPage;