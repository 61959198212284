import React, { useState, useEffect } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import AddCargoTariff from "../modal/AddCargoTariff";

function CargoTariffTable() {
    const [data, setData] = useState([]);
    const [isAddModal, setIsAddModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://amanat-24.ru/api-cargoTariffs');
                const responseData = await response.json();
                setData(responseData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (id, field, value) => {
        // Обновляем состояние только локально
        setData(prevData => {
            return prevData.map(item => {
                if (item.id === id) {
                    return { ...item, [field]: value };
                }
                return item;
            });
        });
    };

    const handleBlur = async (id, field, value) => {
        try {
            // Отправляем данные на сервер
            const response = await fetch(`https://amanat-24.ru/api-updateCargoTariff/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    field: field,
                    value: value
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update data on the server');
            }

            // Обновляем данные после успешного обновления на сервере
            setData(prevData => {
                return prevData.map(item => {
                    if (item.id === id) {
                        return { ...item, [field]: value };
                    }
                    return item;
                });
            });
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const remove = async (id) => {
        
        try {
            const response = await fetch(`https://amanat-24.ru/api-deleteCargoTariff/${id}`, {
                method: 'DELETE'
            });
            if (!response.ok) {
                throw new Error('Ошибка удаления пользователей');
            }
    
            const data = await response.json();
            
        } catch (error) {
            console.error('Ошибка:', error.message);
        }
    }
    
    return (
        <div>
            <div className="flex flex-row space-x-10 px-12 mt-4">
                <div className="space-y-2">
                    <p className="text-xl text-black/50 font-semibold">№</p>
                    {data.map((item) => (
                        <p key={item.id} className="text-xl text-[#302777]">{item.id}</p>
                    ))}
                </div>
                <div className="flex flex-col items-start justify-start space-y-2">
                    <p className="text-xl text-black/50 font-semibold">Тариф в тенге за 1 км</p>
                    {data.map((item) => (
                        <input
                            value={item.capacity ? item.capacity : 'NULL'}
                            className="text-xl text-[#302777]"
                            placeholder={item.capacity ? item.capacity : 'NULL'}
                            onChange={(e) => handleInputChange(item.id, 'capacity', e.target.value)}
                            onBlur={(e) => handleBlur(item.id, 'capacity', e.target.value)}
                        />
                    ))}
                </div>
                <div className="flex flex-col items-start justify-start space-y-2">
                    <p className="text-xl text-black/50 font-semibold">Тариф в тенге за 1 км</p>
                    {data.map((item) => (
                        <input
                            value={item.tariff_per_km ? item.tariff_per_km : 'NULL'}
                            className="text-xl text-[#302777]"
                            placeholder={item.tariff_per_km ? item.tariff_per_km : 'NULL'}
                            onChange={(e) => handleInputChange(item.id, 'tariff_per_km', e.target.value)}
                            onBlur={(e) => handleBlur(item.id, 'tariff_per_km', e.target.value)}
                        />
                    ))}
                </div>
                <div className="flex flex-col items-start justify-start space-y-2">
                    <p className="text-xl text-black/50 font-semibold">НДС</p>
                    {data.map((item) => (
                        <input
                            value={item.vat ? item.vat : 'NULL'}
                            className="text-xl text-[#302777]"
                            placeholder={item.vat ? item.vat : 'NULL'}
                            onChange={(e) => handleInputChange(item.id, 'vat', e.target.value)}
                            onBlur={(e) => handleBlur(item.id, 'vat', e.target.value)}
                        />
                    ))}
                </div>
                <div className="flex flex-col items-start justify-start space-y-2">
                    <p className="text-xl text-black/50 font-semibold">Доход для ТОО</p>
                    {data.map((item) => (
                        <div className="flex flex-row">
                            <input
                                value={item.income_for_company ? item.income_for_company : 'NULL'}
                                className="text-xl text-[#302777]"
                                placeholder={item.income_for_company ? item.income_for_company : 'NULL'}
                                onChange={(e) => handleInputChange(item.id, 'income_for_company', e.target.value)}
                                onBlur={(e) => handleBlur(item.id, 'income_for_company', e.target.value)}
                            />
                            <button onClick={() => remove(item.id)}>
                                <FaRegTrashCan className="text-2xl text-red-500 hover:opacity-25"/>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-screen p-4 justify-center flex">
                <button onClick={() => setIsAddModal(true)} className="w-64 border-2 border-[#2C9356] p-2 rounded-xl group hover:bg-[#2C9356]">
                    <p className="text-xl text-[#2C9356] group-hover:text-white">Добавить запись</p>
                </button>
            </div>
            { isAddModal && <AddCargoTariff onClose={() => setIsAddModal(false)}/>}
        </div>
    )
};

export default CargoTariffTable;