import { IoCloseOutline } from "react-icons/io5";
import { useState } from "react";

function AddDeliveryCapitalTariff({ onClose }) {
    const [pointA, onChangePointA] = useState(null);
    const [pointB, onChangePointB] = useState(null);
    const [tariffPerKm, onChangeTariffPerKm] = useState(null);
    const [vat, onChangeVat] = useState(null);
    const [profiteForCompany, onChangeProfiteForCompany] = useState(null);

    const add = async () => {
        try {
            const response = await fetch('https://amanat-24.ru/api-addDeliveryCapitalTariff', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    pointA,
                    pointB,
                    tariffPerKm,
                    vat,
                    profiteForCompany
                })
            });

            const data = await response.json();

            if (data.success) {
                // Если тариф успешно добавлен, закрываем модальное окно
                onClose();
            } else {
                console.error('Error adding car tariff:', data.message);
            }
        } catch (error) {
            console.error('Error adding car tariff:', error);
        }
    };

    return (
        <div className="fixed top-0 left-0 z-50 h-screen bg-black/15 flex justify-center items-center">
            <div className="bg-white p-6 rounded-2xl px-12 w-3/12">
                <div className="w-full flex flex-row items-end justify-end">
                    <button onClick={onClose} className="hover:opacity-50">
                        <IoCloseOutline className="text-4xl text-[#302777]"/>
                    </button>
                </div>
                <div className="w-full border-2 border-[#302777] p-3 rounded-xl mt-6">
                    <p className="bg-white p-1 text-[#302777] absolute z-1 -mt-8 ml-0">Пункт А</p>
                    <input value={pointA} onChange={e => onChangePointA(e.target.value)} placeholder="Введите значение" className="bg-transparent outline-none text-xl"/>
                </div>
                <div className="w-full border-2 border-[#302777] p-3 rounded-xl mt-6">
                    <p className="bg-white p-1 text-[#302777] absolute z-1 -mt-8 ml-0">Пункт Б</p>
                    <input value={pointB} onChange={e => onChangePointB(e.target.value)} placeholder="Введите значение" className="bg-transparent outline-none text-xl"/>
                </div>
                <div className="w-full border-2 border-[#302777] p-3 rounded-xl mt-6">
                    <p className="bg-white p-1 text-[#302777] absolute z-1 -mt-8 ml-0">Тариф в тенге за 1 км</p>
                    <input value={tariffPerKm} onChange={e => onChangeTariffPerKm(e.target.value)} placeholder="Введите значение" className="bg-transparent outline-none text-xl"/>
                </div>
                <div className="w-full border-2 border-[#302777] p-3 rounded-xl mt-6">
                    <p className="bg-white p-1 text-[#302777] absolute z-1 -mt-8 ml-0">НДС</p>
                    <input value={vat} onChange={e => onChangeVat(e.target.value)} placeholder="Введите значение" className="bg-transparent outline-none text-xl"/>
                </div> 
                <div className="w-full border-2 border-[#302777] p-3 rounded-xl mt-6">
                    <p className="bg-white p-1 text-[#302777] absolute z-1 -mt-8 ml-0">Доход для ТОО</p>
                    <input value={profiteForCompany} onChange={e => onChangeProfiteForCompany(e.target.value)} placeholder="Введите значение" className="bg-transparent outline-none text-xl"/>
                </div>
                <button onClick={add} className="w-full border-2 border-[#2C9356] p-2 mt-6 rounded-xl group hover:bg-[#2C9356]">
                    <p className="text-xl text-[#2C9356] group-hover:text-white">Добавить запись</p>
                </button>
            </div>
        </div>
    )
};

export default AddDeliveryCapitalTariff;