import { CgClose } from "react-icons/cg";

function ViewImage({ onClose, selectedImage }) {
    return (
        <div className="fixed top-0 left-0 z-50 w-screen h-screen bg-black/25 flex">
            <button onClick={onClose} className="absolute z-[100] m-6 bg-white rounded-full w-10 h-10 justify-center items-center flex hover:opacity-50">
                <CgClose className="text-2xl text-black"/>
            </button>
            <img src={`${selectedImage}`} className="absolute z-[70] w-full h-full object-contain"/>
        </div>
    );
};

export default ViewImage;